import Grid from '@material-ui/core/Grid';
import PeButton from '@pe-libs/react-components/build/components/PeButton';
import PeFormCheckbox from '@pe-libs/react-components/build/components/PeFormCheckbox';
import PeFormTextField from '@pe-libs/react-components/build/components/PeFormTextField';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import ButtonContainer from '@pe-libs/react-components/build/components/PeModal/ButtonContainer';
import PeTelInput from '@pe-libs/react-components/build/components/PeTelInput';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useCountries from '@pe-libs/react-components/build/lib/hooks/useCountries';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React from 'react';
import { useSelector } from 'react-redux';
import useRedirect from '#hooks/useRedirect';
import { getUserLocale } from '#modules/app/selectors';
import { convertToPhoneLocale } from './helper';
import messages from './messages';
import SideContainer from './SideContainer';
import useStyles from './styles';
import { CreateLeadWizardProps } from './types';

const Step2: CreateLeadWizardProps = ({ formik }) => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const locale = convertToPhoneLocale(useSelector(getUserLocale) ?? 'de-DE');
  const countries = useCountries({ locale });
  const { urlToLivePage } = useRedirect();
  const mappedCountries = countries.map(({ name, code, prefix }) => ({
    labelId: name,
    title: name,
    value: code,
    prefix: prefix ?? '',
  }));

  return (
    <>
      <SideContainer>
        <PeTypography variant="h4" className={styles.title}>
          {formatMessage(messages.step2title)}
        </PeTypography>
        <PeTypography variant="body2" className={styles.text}>
          {formatMessage(messages.step2desc)}
        </PeTypography>
        <PeTypography variant="body1" gutterBottom={true} className={styles.semiBold}>
          {formatMessage(messages.step2formTitle)}
        </PeTypography>
        <Grid container={true} spacing={6}>
          <Grid xs={12} item={true}>
            <PeFormTextField
              name="page2.fullName"
              formik={formik}
              placeholder={formatMessage(messages.step2namePlaceholder)}
            />
          </Grid>
          <Grid xs={12} sm={6} item={true}>
            <PeFormTextField
              name="page2.emailAddress"
              formik={formik}
              placeholder={formatMessage(messages.step2emailPlaceholder)}
            />
          </Grid>
          <Grid xs={12} sm={6} item={true}>
            <PeTelInput
              name="page2.phoneNumber"
              formik={formik}
              translations={{
                placeholder: formatMessage(messages.telInputPlaceholder),
                placeholderOpen: formatMessage(messages.telInputPlaceholderOpen),
                placeholderOpenForNumber: formatMessage(messages.telInputPlaceholderOpenForNumber),
                favorites: formatMessage(messages.telInputFavorites),
                other: formatMessage(messages.telInputOther),
              }}
              userCountryCode={locale}
              userLangCode={
                locale as 'en-GB' | 'en-US' | 'de-DE' | 'es-ES' | 'fr-FR' | 'it-IT' | 'pt-PT' | 'nl-NL' | 'pl-PL'
              }
              options={mappedCountries}
            />
          </Grid>
        </Grid>
        <div className={styles.required}>
          <span>{formatMessage(messages.required)}</span>
        </div>
        <Grid className={styles.checkbox} container={true} spacing={6}>
          <Grid xs={12} item={true}>
            <PeFormCheckbox
              name="page2.termsOfUse"
              data-testid="rules"
              formik={formik}
              checkboxLabel={
                <div className={styles.termsOfUseDesc}>
                  {formatMessage(messages.termsOfUse, {
                    termsOfUse: (
                      <PeLink target="_blank" href={urlToLivePage('termsAndConditionsOfUse')}>
                        {formatMessage(messages.termsAndConditionsLinkLabel)}
                      </PeLink>
                    ),
                    privacyPolicy: (
                      <PeLink target="_blank" href={urlToLivePage('privacyPolicy')}>
                        {formatMessage(messages.privacyPolicyLinkLabel)}
                      </PeLink>
                    ),
                    br: <br />,
                  })}
                </div>
              }
            />
          </Grid>
        </Grid>
      </SideContainer>
      <ButtonContainer>
        <PeButton
          type="submit"
          color="warning"
          data-testid="createLead-form-submit"
          variant="contained"
          className={styles.button}
          aria-label={`${formatMessage(messages.submit)} button`}
        >
          {formatMessage(messages.submit)}
        </PeButton>
      </ButtonContainer>
    </>
  );
};

export default Step2;
